class ApiComments {
  getCommentsSiteName() {
    return 'stare_aparaty';
  }

  getBaseURL() {
    return '//comments.api.wesoledi.usermd.net';
  }

  getCommentsGetUrl(articleName) {
    return this.getBaseURL() + '/list/' + this.getCommentsSiteName() + '/' + articleName;
  }

  getCommentsAddUrl() {
    return this.getBaseURL() + '/new';
  }

  getCommentsAddPayloadStructure(articleName, text, author) {
    return {
      "site": this.getCommentsSiteName(),
      "article": articleName,
      "text": text,
      "author": author
    }
  }
}

export default ApiComments;
