import React, { Component } from 'react';

export default class TopText extends Component {
  render() {
    if (this.props.mobile) {
      return(
        <svg className="topBarTextMobile">
          <text y="30" x="250" textAnchor="end">Stare Aparaty
          <tspan x="250" y="65">Rosyjskie</tspan>
          </text>
        </svg>
      );
    } else {
      return(
        <svg className="topBarTextDesktop">
          <text y="50">Stare Aparaty Rosyjskie</text>
        </svg>
      );
    }
  }
}
