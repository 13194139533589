import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import HomeIcon from '../../Icons/Home';
import CameraIcon from '../../Icons/Camera';
import MeterIcon from '../../Icons/Meter';
import { NavLink } from 'react-router-dom';

class DrawerItems extends Component {
  getInnerNavItems() {
    if (this.props.innerNavItems) {
      return (
        <div>
        <hr/>
          {this.props.innerNavItems.map(item => 
            <NavLink to={item.link} key={item.link}>
              <Button className="menuButton" style={{justifyContent: 'left', paddingLeft: '15px'}}>
                {item.name}
              </Button>
            </NavLink>
          )}
        </div>
      );
    }
  }

  render() {
    return (
      <div className="menuBox">
      <NavLink to="/">
        <Button className="menuButton" style={{justifyContent: 'left'}}>
          <HomeIcon className="menuIcon"/><span className="menuLabel">Start</span>
        </Button>
      </NavLink>
      <NavLink to="/zorkij4">
        <Button className="menuButton" style={{justifyContent: 'left'}}>
          <CameraIcon className="menuIcon"/><span className="menuLabel">Zorkij 4</span>
        </Button>
      </NavLink>
      <NavLink to="/zenit12xp">
        <Button className="menuButton" style={{justifyContent: 'left'}}>
          <CameraIcon className="menuIcon"/><span className="menuLabel">Zenit 12XP</span>
        </Button>
      </NavLink>
      <NavLink to="/leningrad">
        <Button className="menuButton" style={{justifyContent: 'left'}}>
          <MeterIcon className="menuIcon"/><span className="menuLabel">Leningrad</span>
        </Button>
      </NavLink>
      {this.getInnerNavItems()}
    </div>
    );
  }
}

export default DrawerItems
