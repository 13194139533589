import React, { Component } from 'react';
import txt from '../../Statics/Articles/Super.txt';
import BottomItems from '../Navigations/NavElements/BottomItems';

class ContentSuper extends Component {
  constructor(txtPath = txt) {
    super();
    this.state = {
      text: 'Ładowanie...'
    };

    fetch(txtPath)
    .then((r) => r.text())
    .then(textReaded  => {
      const textReadedParsed = this.parseImages(textReaded);
      this.setState({ text: textReadedParsed });
    })
  }

  componentDidMount() {
    this.props.onRender();
  }

  render() {
    return(
      <div className="contentContainer">
      </div>
    );
  }

  getBottomIcons() {
    return (
      <BottomItems />
    );
  }

  parseImages(text) {
    let newText = text;
    let imgTags = text.match(/(IMG::)(.*?).+/g);
    if (imgTags === null) {
      return text;
    } else {
      imgTags = imgTags.map(e => e.replace(/(IMG::)/g, ''));
    }
    imgTags.map((e) => {
      const imgSrc = require(`../../Statics/Images/${e}`);
      const regexTemp = new RegExp(`(IMG::)(${e})`, 'g');
      newText = newText.replace(regexTemp, `<img src=${imgSrc} class="imageContent">`);
      return e;
    });
    return newText;
  }
}

export default ContentSuper
