import React, { Component } from 'react';
import { Breakpoint } from 'react-socks';
import { CSSTransitionGroup } from 'react-transition-group';
import CommentsList from './ComElements/CommentsList';
import CommentAddForm from './ComElements/CommentAddForm';
import RandomGenerator from '../Services/RandomGenerator';

class Comments extends Component {
  randomGenerator = new RandomGenerator();

  constructor(props) {
    super(props);
    this.state = {
      correlator: 0
    }

    this.getBody = this.getBody.bind(this);
  }

  setNewCorrelator() {
    this.setState({correlator: this.randomGenerator.getNumberBetween(1000, 9999)});
  }

  getBody(additionalClassName) {
    const className = `commentsContainer ${additionalClassName}`;
    return (
      <div className={ className }>
        <h3>Komentarze:</h3>
        <CommentsList article={this.props.article} correlator={this.state.correlator}/>
        <h3 style={{ marginBlockEnd: 0 }}>Dodaj komentarz:</h3>
        <CommentAddForm article={this.props.article} onCommentAdd={() => {this.setNewCorrelator()}}/>
      </div>
    );
  }

  render () {
    return(
    <CSSTransitionGroup
          transitionName="contentTransition"
          transitionAppear={true}
          transitionAppearTimeout={500}
          transitionEnter={false}
          transitionLeave={false}>
      <hr/>
      <Breakpoint small down>
        { this.getBody() }
      </Breakpoint>

      <Breakpoint medium up>
        { this.getBody('withDrawer') }
      </Breakpoint>
    </CSSTransitionGroup>
    )
  }
}

export default Comments;
