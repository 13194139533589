import React, { Component } from 'react';
import Fab from '@material-ui/core/Fab';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';

export default class BottomItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yPos: 0
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
  }

  listenToScroll = () => {
    this.setState({
      yPos: window.pageYOffset,
    })
  }

  moveUp() {
    window.scrollTo(0, 0);
  }

  render() {
    if (this.state.yPos >= 400) {
      return (
        <Fab aria-label="Wróć na górę" className="contentBottomIcon contentBottomIconUp">
          <UpIcon onClick={this.moveUp}/>
        </Fab>
      );
    } else {
      return '';
    }
  }
}
