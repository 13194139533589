export default class FormValidator {
  static MINIMAL_FIELD_TEXT_LENGTH = 5;

  validateFieldText(text) {
    return (text.length > FormValidator.MINIMAL_FIELD_TEXT_LENGTH);
  }

  validateFieldTextErrorMessage() {
    return 'Brak lub za krótki wpis w danym polu ... ';
  }
}
