import React, { Component } from 'react';

import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';

import Avatar from './NavElements/Avatar';
import DrawerItems from './NavElements/DrawerItems';
import FooterLittle from './NavElements/FooterLittle';

import '../../Styles/menu.css';

import  { Breakpoint } from 'react-socks';

class Menu extends Component {
  render() {
    return(
      <div>
        <Breakpoint small down>
          <Drawer
            anchor='left'
            open={this.props.mobileOpen}
            onClose={this.props.onCloseClick}
            classes={{
              paper: "menuPaper",
            }}
          >
          <Button
            className="menuButton"
            style={{justifyContent: 'left'}}
            onClick={this.props.onCloseClick}
          >
            Close
          </Button>
            <Avatar />
            <DrawerItems />
            <FooterLittle/>
          </Drawer>
        </Breakpoint>

        <Breakpoint medium up>
          <Drawer
            anchor='left'
            open
            variant="permanent"
            classes={{
              paper: "menuPaper",
            }}
          >
            <Avatar />
            <DrawerItems innerNavItems={this.props.innerNavItems}/>
            <FooterLittle/>
          </Drawer>
        </Breakpoint>
      </div>
    )
  }
}

export default Menu
