import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import Typography from '@material-ui/core/Typography';

import '../../Styles/card-new.scss';

class CardView extends Component {
  render() {
    return(
      <div className="articleCard" style={{ backgroundImage: "url('" + this.props.img + "')" }}>
        <div className="articleCard--infoBox">
        <Typography gutterBottom variant="h5" component="h3">
          <strong>{this.props.title}</strong>
        </Typography>

        <Typography variant="body2" color="textSecondary" component="span">
          <ReactMarkdown source={this.props.text} escapeHtml={true} />
        </Typography>
        </div>
      </div>
    )
  }
}

export default CardView
