import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';

import '../../Styles/comments.css';

class CommentView extends Component {
  exposeAuthorSuffix(highlightFlag) {
    if (!highlightFlag) {
      return '';
    }

    return (
      <span className="authorSuffix"> (autor)</span>
    );
  }

  render() {
    return(
    <div className={`commentQuote ${this.props.highlight ? "authorLight" : ""}`}>
      <strong className="commentsAuthor">{this.props.author}</strong> 
      {this.exposeAuthorSuffix(this.props.highlight)}
      &nbsp;<span className="commentsTime">(dnia: <i>{this.props.time}</i>)</span>:
      <ReactMarkdown source={this.props.text} escapeHtml={true} />
    </div>
    )
  }
}

export default CommentView
