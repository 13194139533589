import React, { Component } from 'react';
import '../../../Styles/avatar.css';
import img from '../../../Statics/Images/avatar.png';

class Avatar extends Component {
  render() {
    return(
      <div className="avatarRoot">
        <img src={img} alt="Avatar" className="avatarImg"/>
      </div>
    )
  }
}

export default Avatar
