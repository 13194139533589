import React, { Component } from 'react';
import txt from '../../Statics/Cards/Super.txt';

import Card from '@material-ui/core/Card';

class CardSuper extends Component {
  constructor(txtPath = txt) {
    super();
    this.state = {
      text: 'Ładowanie...'
    };

    fetch(txtPath)
    .then((r) => r.text())
    .then(textReaded  => {
      this.setState({ text: textReaded });
    })
  }

  render() {
    return(
      <Card className="homeCard">
      </Card>
    )
  }
}

export default CardSuper
