import React, { Component } from 'react';
import Menu from './Components/Navigations/Menu';
import Topbar from './Components/Navigations/Topbar';
import ContentRoutes from './Components/Contents/ContentRoutes';
import './Styles/main.css';
import { BreakpointProvider } from 'react-socks';
import { loadReCaptcha } from 'react-recaptcha-v3'

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      innerNavItems: null
    };
  }

  componentDidMount() {
    loadReCaptcha('6LeSabQUAAAAANBP2gr9RQAC-cmsDsnbFjs2sfVe');
  }

  menuOpen() {
    this.setState({ mobileOpen: true });
  }

  menuClose() {
    this.setState({ mobileOpen: false });
  }

  handleRouteChange(route) {
    // TO DO: adding paragraphs to menu?
  }

  render() {
    return (
      <BreakpointProvider>
        <div className="root">
          <Topbar onClick={() => this.menuOpen() }/>
          <Menu 
            innerNavItems={this.state.innerNavItems} 
            mobileOpen={ this.state.mobileOpen } 
            onCloseClick={() => this.menuClose() }
          />
          <ContentRoutes onChange={(route) => this.handleRouteChange(route) }/>
        </div>
      </BreakpointProvider>
    );
  }
}

export default App;
