import React from 'react';
import txt from '../../../Statics/Cards/Leningrad.txt';
import img from '../../../Statics/Images/leningrad7.JPG';
import CardSuper from '../CardSuper';
import CardView from '../../Views/CardView';
import { Link } from 'react-router-dom';

class Leningrad7 extends CardSuper {
  name = 'Światłomierz LENINGRAD';

  constructor() {
    super(txt);
  }

  render () {
    return (
      <Link className="articleCard--link" to="/leningrad">
        <CardView img={img} title={this.name} text={this.state.text} />
      </Link>
    );
  }
}

export default Leningrad7
