import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './Home';
import Zorka4 from './Articles/Zorka4';
import Zenit12xp from './Articles/Zenit12xp';
import Leningrad from './Articles/Leningrad';
import e404View from '../Views/e404View';

const ContentRoutes = (props) => {
  return(
    <Switch>
      <Route exact path="/" render={p => <Home onRender={() => props.onChange('start')}/>}/>
      <Route path="/zorka4" render={p => <Zorka4 onRender={() => props.onChange('zorkij')}/>}/>
      <Route path="/zorkij4" render={p => <Zorka4 onRender={() => props.onChange('zorkij')}/>}/>
      <Route path="/zenit12xp" render={p => <Zenit12xp onRender={() => props.onChange('zenit')}/>}/>
      <Route path="/leningrad" render={p => <Leningrad onRender={() => props.onChange('leningrad')}/>}/>
      <Route component={e404View} />
    </Switch>
  )
}

export default ContentRoutes
