import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const MeterIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M6,2H18A2,2 0 0,1 20,4V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2M6,4V8H18V4H6Z" />
    </SvgIcon>
  );
};

export default MeterIcon;
