class ApiRecaptcha {
  getBaseURL() {
    return '//comments.api.wesoledi.usermd.net';
  }

  getSiteVerifyUrl() {
    return this.getBaseURL() + '/recaptcha';
  }
}

export default ApiRecaptcha;
