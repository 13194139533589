import React, { Component } from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import ReactMarkdown from 'react-markdown';
import { Breakpoint } from 'react-socks';

import '../../Styles/content.css';


class ArticleView extends Component {
  render() {
    return(
      <CSSTransitionGroup
        transitionName="contentTransition"
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave={false}>
          <Breakpoint small down>
            <div className="articleView">
              <ReactMarkdown 
                source={this.props.text} 
                escapeHtml={false}
              />
              <p className="copyright">© by Adam Wesołowski - 'wesoledi' 2010-2019</p>
            </div>
          </Breakpoint>

          <Breakpoint medium up>
            <div className="articleView withDrawer">
              <ReactMarkdown source={this.props.text} escapeHtml={false} />
              <p className="copyright">© by Adam Wesołowski - 'wesoledi' 2010-2019</p>
            </div>
          </Breakpoint>
      </CSSTransitionGroup>
    )
  }
}

export default ArticleView
