import React from 'react';
import Message from '../Notices/Message';
import ErrorMessage from '../Notices/ErrorMessage';
import SuccessMessage from '../Notices/SuccessMessage';

export default class MessageService {
  static ERROR_MESSAGE = 'error';
  static SUCCESS_MESSAGE = 'success';

  _visible = false;
  _text = '';
  _type = null;
  _callbackAfterRender = () => {};

  constructor(type, callbackAfterRender) {
    this._type = type;
    this._callbackAfterRender = callbackAfterRender;
  }

  showMessage(text) {
    this._text = text;
    this._visible = true;
    this._callbackAfterRender();
  }

  hideMessage() {
    this._visible = false;
    this._callbackAfterRender();
  }

  isVisible() {
    return this._visible;
  }

  getMessageElement() {
    switch (this._type) {
      case MessageService.ERROR_MESSAGE:
        return <ErrorMessage
          text={this._text}
          onClose={
            () => {this.hideMessage()}
          }
        />
      case MessageService.SUCCESS_MESSAGE:
        return <SuccessMessage
          text={this._text}
          onClose={
            () => {this.hideMessage()}
          }
        />
      default:
        return <Message
          text={this._text}
          type="none"
          onClose={
            () => {this.hideMessage()}
          }
        />
    }
  }
}
