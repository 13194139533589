import React, { Component } from 'react';
import '../../../Styles/footer.css';
import FacebookIcon from '../../Icons/Facebook';
import { NavLink } from 'react-router-dom';

class FooterLittle extends Component {
  render() {
    return (
      <div className="footerLittleRoot">
        <ul>
          <li><strong>Adam Wesołowski</strong></li>
          <li>
          <NavLink to="//www.facebook.com/wesoledi.foto">
            <FacebookIcon
              fontSize="small"
              classes={{
                fontSizeSmall: "footerLittleIconFontSizeSmall",
              }}
            />/wesoledi.foto
          </NavLink>
          </li>
        </ul>
      </div>
    );
  }
}

export default FooterLittle;
