import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import '../../Styles/messages.css';

export default class Message extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };

    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
    this.props.onClose();
  }

  render() {
    const transitionDown = (props) => {
      return <Slide {...props} direction="down"
      classes={
        {root: this.props.type}
      }/>;
    }

    return (
      <div>
        <Snackbar
          open={this.state.open}
          onClose={this.handleClose}
          TransitionComponent={transitionDown}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          autoHideDuration={3000}
          message={<span id="message-id">{this.props.text}</span>}
        />
      </div>
    );
  }
}
