import React, { Component } from 'react';
import CommentView from '../../Views/CommentView';
import ApiComments from '../../../Config/ApiComments';

class CommentsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      correlator: this.props.correlator
    };
  }

  viewComment(comment) {
    return (
        <CommentView
          key={comment.id}
          text={comment.text}
          author={comment.author}
          time={comment.time.date.substr(0, 19)}
          highlight={comment.highlight}
        />
    );
  }

  componentDidMount() {
    this.getList();
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate() {
    if (this.props.correlator !== this.state.correlator) {
      this.setState({ correlator: this.props.correlator });
      setTimeout(() => {this.getList()}, 500);
    }
  }

  getList() {
    const api = new ApiComments();
    fetch(api.getCommentsGetUrl(this.props.article))
      .then(res => res.json())
      .then(json => {
        if (this._isMounted) {
          this.setState({ comments: json.data.comments });
        }
      });
  }

  render () {
    return this.state.comments.map((c) => {
      return this.viewComment(c)
    });
  }
}

export default CommentsList;
