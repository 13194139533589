import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import '../../Styles/topbar.css';
import  { Breakpoint } from 'react-socks';
import RandomGenerator from '../Services/RandomGenerator';
import TopText from './TopbarElements/TopText';

import img1 from '../../Statics/Images/topbar4.jpg';
import img2 from '../../Statics/Images/topbar5.jpg';
import img3 from '../../Statics/Images/topbar6.jpg';
import img4 from '../../Statics/Images/topbar7.jpg';
import img5 from '../../Statics/Images/topbar8.jpg';

class Topbar extends Component {
  getImageList() {
    return [
      img1,
      img2,
      img3,
      img4,
      img5,
    ];
  }

  getAppTitle(isMobile = false) {
    return <TopText className="topBarText" mobile={isMobile}/>;
  }

  getBackgroundImageStyle() {
    const imgList = this.getImageList();
    const randomizer = new RandomGenerator();
    const imgIdx = randomizer.getNumberBetween(0, imgList.length);
    return {
      backgroundImage: 'url("' + imgList[imgIdx] + '")',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    };
  }

  render() {
    return (
      <div className="topBarRoot">
        <AppBar
          position="static"
          classes={{
            colorPrimary: "topBarColors",
            root: "topBarApp"
          }}
          style={this.getBackgroundImageStyle()}
        >
          <Breakpoint small down>
            <Toolbar className="topBarToolbarMobile">
              <IconButton 
                edge="start" 
                className="topBarButton" 
                color="inherit" 
                aria-label="menu" 
                onClick={this.props.onClick}
              >
                <MenuIcon/>
              </IconButton>
              <Typography variant="h3" className="topBarTitle">
                {this.getAppTitle(true)}
              </Typography>
            </Toolbar>
          </Breakpoint>
          <Breakpoint medium up>
            <Toolbar className="topBarToolbarDesktop">
              <Typography variant="h3" className="topBarTitle">
                {this.getAppTitle()}
              </Typography>
            </Toolbar>
          </Breakpoint>

        </AppBar>
      </div>
    );
  }
}

export default Topbar;
