import React, { Component } from 'react';
import ApiComments from '../../../Config/ApiComments';
import ApiRecaptcha from '../../../Config/ApiRecaptcha';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { ReCaptcha } from 'react-recaptcha-v3'
import FormValidator from '../../Services/FormValidator';

import MessageService from '../../Services/MessageService';

class CommentAddForm extends Component {
  messageServiceError;
  messageServiceSuccess;

  constructor(props) {
    super(props);
    this.state = {
      author: '',
      text: '',
      submitDisabled: true,
      authorError: false,
      textError: false
    };

    this.messageServiceError = new MessageService(
      MessageService.ERROR_MESSAGE,
      () => {this.forceUpdate()}
    );
    this.messageServiceSuccess = new MessageService(
      MessageService.SUCCESS_MESSAGE,
      () => {this.forceUpdate()}
    );

    this.handleAuthorChange = this.handleAuthorChange.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.verifyRecaptha = this.verifyRecaptha.bind(this);
    this.refreshList = this.refreshList.bind(this);
  }

  componentDidMount() { 
    this._isMounted = true;
  }
  
  componentWillUnmount() {
    this._isMounted = false;
  }

  refreshList() {
    this.props.onCommentAdd();
  }

  handleAuthorChange(event) {
    this.setState({ author: event.target.value });
  }

  handleTextChange(event) {
    this.setState({ text: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    const validator = new FormValidator();
    const textValidatedAsOk = validator.validateFieldText(this.state.text);
    const authorValidatedAsOk = validator.validateFieldText(this.state.text);

    this.setState({ authorError: !authorValidatedAsOk });
    this.setState({ textError: !textValidatedAsOk });

    if (!authorValidatedAsOk || !textValidatedAsOk) {
      this.messageServiceError.showMessage(
        'Minimalna ilość znaków w polu wynosi: ' + FormValidator.MINIMAL_FIELD_TEXT_LENGTH
      );
      return;
    }

    const apiComments = new ApiComments();
    const siteName = apiComments.getCommentsSiteName();
    const payload = JSON.stringify({
      "site": siteName,
      "article": this.props.article,
      "content": this.state.text,
      "author": this.state.author
    });
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('s-api-key', 'fb375b71d28e2488784e6ea44f4fa8c3');

    fetch(apiComments.getCommentsAddUrl(), {
      method: 'post',
      headers: headers,
      body: payload
    });

    this.messageServiceSuccess.showMessage('Dodano komentarz!');
    this.setState({ submitDisabled: true });
    this.setState({ author: '' });
    this.setState({ text: '' });
    this.refreshList();
  }

  verifyRecaptha(recaptchaToken) {
    const apiComments = new ApiComments();
    const apiRecaptcha = new ApiRecaptcha();
    const siteName = apiComments.getCommentsSiteName();
    const payload = JSON.stringify({
      "site": siteName,
      "token": recaptchaToken,
    });
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('s-api-key', 'fb375b71d28e2488784e6ea44f4fa8c3');

    fetch(apiRecaptcha.getSiteVerifyUrl(), {
      method: 'post',
      headers: headers,
      body: payload
    })
      .then(res => res.json())
      .then(json => {
        const result = !json.data.result;
        if (this._isMounted) {
          this.setState({ submitDisabled: result });
        }
      });
  }

  render () {
    return (
      <div>
        {this.messageServiceError.isVisible() && this.messageServiceError.getMessageElement()}
        {this.messageServiceSuccess.isVisible() && this.messageServiceSuccess.getMessageElement()}

        <form className="commentInputFormBox" noValidate autoComplete="off" onSubmit={this.handleSubmit}>
          <TextField
            id="author"
            label="Autor"
            className="commentInputSingleLine"
            onChange={ this.handleAuthorChange }
            value={this.state.author}
            margin="normal"
            variant="outlined"
            error={this.state.authorError}
            fullWidth
            classes={{
              root: "commentInputElementRoot",
            }}
          />
          <TextField
            id="text"
            label="Tekst"
            className="commentInputMultiLine"
            onChange={ this.handleTextChange }
            value={this.state.text}
            margin="normal"
            variant="outlined"
            error={this.state.textError}
            multiline
            rows="4"
            rowsMax="4"
            fullWidth
            classes={{
              root: "commentInputElementRoot",
            }}
          />
          <div>
            <div>
              <ReCaptcha
                sitekey="6LeSabQUAAAAANBP2gr9RQAC-cmsDsnbFjs2sfVe"
                action='commentsAdd'
                verifyCallback={this.verifyRecaptha}
              />
            </div>
            <div>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                classes={{
                  root: "commentInputSubmit",
                }}
                disabled={this.state.submitDisabled}
              > Dodaj! </Button>
            </div>
          </div>
          <div>
            <p className="commentInputFootInfo">
              Strona oraz formularz są chronione przez <strong>reCAPTCHA</strong> od <strong>Google</strong>.
              <span> <a href="https://policies.google.com/privacy">Polityka prywatnościy</a></span> i
              <span> <a href="https://policies.google.com/terms">Warunki korzystania z usług</a></span> mają zastosowanie.
            </p>
          </div>
        </form>
      </div>
    );
  }
}

export default CommentAddForm;
