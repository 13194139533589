import React from 'react';
import ContentSuper from '../ContentSuper';
import ArticleView from '../../Views/ArticleView';
import Comments from '../../Comments/Comments';
import txt from '../../../Statics/Articles/Zenit12xp.txt';

class Zenit12xp extends ContentSuper {
  constructor() {
    super(txt);
  }

  render () {
    return(
      <div className="contentContainer">
        <ArticleView text={this.state.text} />
        <Comments article="zenit12xp"/>
        {this.getBottomIcons()}
      </div>
    )
  }
}

export default Zenit12xp
