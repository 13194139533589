import React from 'react';
import txt from '../../../Statics/Cards/Zenit12xp.txt';
import img from '../../../Statics/Images/zenit12xp.JPG';
import CardSuper from '../CardSuper';
import CardView from '../../Views/CardView';
import { Link } from 'react-router-dom';

class Zenit12XP extends CardSuper {
  name = 'Aparat ZENIT 12 XP';


  constructor() {
    super(txt);
  }

  render () {
    return (
      <Link className="articleCard--link" to="/zenit12xp">
        <CardView img={img} title={this.name} text={this.state.text} />
      </Link>
    );
  }
}

export default Zenit12XP
