import React, { Component } from 'react';

class e404View extends Component {
  render() {
    return <div>
      -- 404 --
    </div>;
  }
}

export default e404View
