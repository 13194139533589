import React from 'react';
import ContentSuper from '../ContentSuper';
import ArticleView from '../../Views/ArticleView';
import Comments from '../../Comments/Comments';
import txt from '../../../Statics/Articles/Zorka4.txt';

class Zorka4 extends ContentSuper {
  constructor() {
    super(txt);
  }

  render () {
    return(
      <div className="contentContainer">
        <ArticleView text={this.state.text} />
        <Comments article="zorka4"/>
        {this.getBottomIcons()}
      </div>
    )
  }
}

export default Zorka4
