import React, { Component } from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import  { Breakpoint } from 'react-socks';
import '../../Styles/content.css';
import '../../Styles/home-more.scss';

import Zorka4 from './Cards/Zorka4';
import Zenit12XP from './Cards/Zenit12xp';
import Leningrad7 from './Cards/Leningrad7';

class Home extends Component {
  componentDidMount() {
    this.props.onRender();
  }

  startContent() {
    return (
      <div className="homeWelcomeContent">
        <p>Witam na mojej stronie, na której znajdziecie opisy obsługi starych aparatów radzieckich (rosyjskich).</p>
        <p>Niniejsze instrukcje powstały jako efekt moich wieloletnich doświadczeń z przedstawionymi poniżej aparatami, oraz innymi urządzeniami, w tym światłomierz z epoki, który był świetnym kompanem przy użytkowaniu kilku z opisanych tu aparatów. </p>
        <p>Opisy przedstawiają tym samym moją zdobytą dotychczas wiedzę z nimi związaną. Starałem się tym samym dodać jak najwięcej od siebie, zamiast tylko prosto opisać poszczególne pokrętła. Mam nadzieję, że dzięki temu aparaty będą przystępniejsze dla tych, którzy chcą się z nimi zaznajomić. A warto, nawet na początku przygody z fotografią. Dużo mnie bowiem to nauczyło. Uczyłem się na nich, zanim jeszcze "dotknąłem" lustrzanki cyfrowej. </p>
        <p className="annotation">Na koniec pragnę dodać, że opisy te są moim autorstwem, podlegają prawu autorskiemu i nie zgadzam się na ich kopiowanie bez mojej wiedzy na inne strony. Ale jeśli chcesz się podzielić instrukcją? Nie ma sprawy: podaj linka znajomemu. Z góry dzięki!</p>
        <div className="articleCards">
          <Zorka4 />
          <Zenit12XP />
          <Leningrad7 />
        </div>
      </div>
    );
  }

  startContentRoot() {
    return (
      <div>
        <Breakpoint small down>
          <div className="articleView">
            {this.startContent()}
          </div>
        </Breakpoint>

        <Breakpoint medium up>
          <div className="articleView withDrawer">
            {this.startContent()}
          </div>
        </Breakpoint>
      </div>
    );
  }

  render () {
    return(
      <CSSTransitionGroup
        transitionName="contentTransition"
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave={false}>
          <div className="contentContainer">
            {this.startContentRoot()}
          </div>
      </CSSTransitionGroup>
    )
  }
}

export default Home
